body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #b8b8b8; }

.underConstruction {
  height: 100vh;
  background-image: url(/static/media/dual-BG.8af0e45b.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: white; }
  .underConstruction .myName {
    font-size: 4rem;
    margin-top: 0;
    padding-top: 230px; }
    @media (max-width: 425px) {
      .underConstruction .myName {
        font-size: 3rem; } }
  .underConstruction .heroSubTitle {
    font-size: 2rem; }
  .underConstruction .comingSoon {
    font-size: 1.5rem; }
  .underConstruction .linkLogos img {
    width: 5rem;
    padding: 5px 15px; }

.mainPage {
  position: absolute;
  top: 75px;
  left: 0;
  right: 0; }

.fade-enter {
  opacity: 0.01;
  z-index: 1; }

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in; }

.fade-exit {
  opacity: 1; }

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 1000ms ease-in; }

.navbar {
  background: #525252;
  background: -webkit-gradient(left top, right top, color-stop(0%, #525252), color-stop(45%, #5c5c5c), color-stop(61%, #a1a1a1), color-stop(100%, #b8b8b8));
  background: linear-gradient(to right, #525252 0%, #5c5c5c 45%, #a1a1a1 61%, #b8b8b8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#525252', endColorstr='#b8b8b8', GradientType=1 );
  height: 75px;
  width: 100vw;
  position: fixed;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 768px) {
    .navbar {
      justify-content: space-between; } }
  @media (max-width: 425px) {
    .navbar .navbar__links a {
      font-size: 1.2rem; } }
  .navbar .myName {
    display: none; }
    @media (min-width: 768px) {
      .navbar .myName {
        display: block;
        padding-left: 35px; } }
    .navbar .myName h3 {
      color: white;
      font-size: 1rem; }
      @media (min-width: 1024px) {
        .navbar .myName h3 {
          font-size: 1.5rem; } }
  .navbar a {
    color: white;
    text-decoration: none;
    font-size: 2rem;
    cursor: pointer; }
  .navbar .navbar__webLink {
    margin-right: 50px; }
  .navbar #activeUnderline {
    border-bottom: thick white solid; }
  .navbar #inactiveUnderline {
    border: none; }
  .navbar .navbar__logos {
    display: none;
    padding-right: 36px; }
    @media (min-width: 768px) {
      .navbar .navbar__logos {
        display: block;
        padding-left: 35px; } }
    .navbar .navbar__logos img {
      padding-top: 5px;
      padding-right: 3px;
      height: 50px; }

.heroSection {
  height: 500px;
  color: white;
  position: relative; }
  .heroSection h1 {
    position: absolute;
    top: 20%;
    text-align: left;
    width: 85%;
    font-size: 3rem;
    padding-left: 10%;
    margin-top: 5px; }
    @media (min-width: 768px) {
      .heroSection h1 {
        font-size: 4rem;
        width: 78%;
        padding-left: 17%;
        margin: 10px auto; } }
    @media (min-width: 1024px) {
      .heroSection h1 {
        padding-left: 21%; } }
    @media (min-width: 1440px) {
      .heroSection h1 {
        margin-top: 54px;
        padding-left: 21%; } }

.webHero {
  background-image: url(/static/media/webbg.4c768d4c.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.videoHero {
  background-image: url(/static/media/videobg.c41ebdb6.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  .videoHero h1 {
    padding-left: 9%;
    top: 15%; }
    @media (min-width: 768px) {
      .videoHero h1 {
        padding-left: 15%; } }
    @media (min-width: 1024px) {
      .videoHero h1 {
        top: 20%; } }

.bioSection {
  padding-bottom: 20px; }
  .bioSection .bioSection__header {
    text-align: center;
    font-size: 2rem;
    margin-top: 0;
    padding-top: 20px; }
  .bioSection .stackAndBackground {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; }
    .bioSection .stackAndBackground .webTech img {
      width: 90%;
      padding-left: 25px; }
      @media (min-width: 768px) {
        .bioSection .stackAndBackground .webTech img {
          margin-right: 20px; } }
    @media (min-width: 768px) {
      .bioSection .stackAndBackground .webTech {
        width: 50%; } }
    .bioSection .stackAndBackground .background_text {
      width: 90%;
      font-size: 1.2rem; }
      @media (min-width: 768px) {
        .bioSection .stackAndBackground .background_text {
          width: 90%;
          padding-right: 15px; } }
      @media (min-width: 1024px) {
        .bioSection .stackAndBackground .background_text {
          width: 40%; } }

.webBio {
  background-color: #525252;
  color: white;
  text-align: left; }

.videoBio {
  background-color: #b8b8b8;
  color: black;
  text-align: left; }

.projects .projects__header {
  margin-top: 0;
  padding-top: 20px;
  font-size: 2rem; }

.projects .projectCards {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap; }

.webBackground {
  background-color: #525252; }

.webLightText {
  color: white; }

.videoBackground {
  background-color: #b8b8b8; }

.videoDarkText {
  color: black; }

.card {
  height: 400px;
  width: 400px;
  border-radius: 5px;
  box-shadow: 2px 2px 2px -1px rgba(0, 0, 0, 0.75);
  background-color: white;
  margin: 20px; }
  .card img {
    width: 100%;
    height: 50%;
    object-fit: cover; }
  .card .card__text {
    width: 90%;
    text-align: left;
    margin: 0 auto;
    position: relative; }
    .card .card__text .projectLink {
      position: absolute;
      top: 146px;
      text-decoration: none; }

.quotes-section {
  background-color: #525252;
  padding-bottom: 20px; }
  .quotes-section h1 {
    color: white;
    font-size: 2rem;
    margin: 0;
    padding-top: 20px; }
  .quotes-section .quotes-box {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap; }
    .quotes-section .quotes-box .quote-text {
      max-width: 500px;
      color: white;
      text-align: left;
      display: flex;
      justify-content: center;
      margin-top: 16px; }
      .quotes-section .quotes-box .quote-text .quote-body {
        font-size: 1.5rem; }
        @media (max-width: 425px) {
          .quotes-section .quotes-box .quote-text .quote-body {
            font-size: 1rem; } }
      .quotes-section .quotes-box .quote-text .opening-quote {
        height: 40px;
        padding-right: 15px; }
        @media (max-width: 425px) {
          .quotes-section .quotes-box .quote-text .opening-quote {
            height: 50px; } }
      .quotes-section .quotes-box .quote-text .closing-quote {
        height: 40px;
        padding-top: 150px;
        padding-left: 15px; }
        @media (max-width: 425px) {
          .quotes-section .quotes-box .quote-text .closing-quote {
            height: 50px;
            padding-top: 0; } }

.contactMe {
  padding-bottom: 50px; }
  .contactMe .contactMe__header {
    margin-top: 0;
    padding-top: 10px;
    font-size: 2rem; }
  .contactMe .contact__Circles .myPortrait {
    border-radius: 50%;
    height: 166px;
    border: 17px black solid; }
  .contactMe .contact__Circles img {
    height: 200px;
    margin: 0 10px; }

.greyBg {
  background-color: #b8b8b8; }

.artHero {
  background-image: url(/static/media/artstock.93fb4e4f.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  .artHero h1 {
    padding-left: 9%;
    top: 37%; }
    @media (min-width: 768px) {
      .artHero h1 {
        padding-left: 15%; } }
    @media (min-width: 1024px) {
      .artHero h1 {
        top: 38%; } }
    @media (min-width: 1025px) {
      .artHero h1 {
        top: 31%; } }

.comicsMap {
  padding: 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap; }
  .comicsMap .comic-thumbnail {
    width: 100px;
    height: 170px;
    padding: 10px;
    border: thin black solid;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
    .comicsMap .comic-thumbnail img {
      width: 100%; }

.comicModalBody .comicModalHeader {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center; }
  .comicModalBody .comicModalHeader .closeButton {
    height: 50px;
    width: 100px;
    background-color: red;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 1.2rem; }

.comicModalBody img {
  width: 100%; }

