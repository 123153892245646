$mobile:425px;
$tablet:768px;
$desktop:1024px;

body{
    font-family: 'Roboto', sans-serif;
    background-color: rgba(184,184,184,1);
}

// under construction styles
.underConstruction{
    height: 100vh;
    background-image: url('./dual-BG.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    color:white;
    .myName{
        font-size: 4rem;
        margin-top:0;
        padding-top:230px;
        @media(max-width: 425px){
            font-size:3rem;
        }
    }
    .heroSubTitle{
        font-size: 2rem;
    }
    .comingSoon{
        font-size: 1.5rem;
    }
    .linkLogos{
        img{
            width:5rem;
            padding: 5px 15px;
        }
    }
}

.mainPage{
    position: absolute;
    top:75px;
    left:0;
    right:0;
}

.fade-enter {
    opacity: 0.01;
    z-index: 1;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 1000ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 1000ms ease-in;
}


.navbar{
    background: rgba(82,82,82,1);
    background: -moz-linear-gradient(left, rgba(82,82,82,1) 0%, rgba(92,92,92,1) 45%, rgba(161,161,161,1) 61%, rgba(184,184,184,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(82,82,82,1)), color-stop(45%, rgba(92,92,92,1)), color-stop(61%, rgba(161,161,161,1)), color-stop(100%, rgba(184,184,184,1)));
    background: -webkit-linear-gradient(left, rgba(82,82,82,1) 0%, rgba(92,92,92,1) 45%, rgba(161,161,161,1) 61%, rgba(184,184,184,1) 100%);
    background: -o-linear-gradient(left, rgba(82,82,82,1) 0%, rgba(92,92,92,1) 45%, rgba(161,161,161,1) 61%, rgba(184,184,184,1) 100%);
    background: -ms-linear-gradient(left, rgba(82,82,82,1) 0%, rgba(92,92,92,1) 45%, rgba(161,161,161,1) 61%, rgba(184,184,184,1) 100%);
    background: linear-gradient(to right, rgba(82,82,82,1) 0%, rgba(92,92,92,1) 45%, rgba(161,161,161,1) 61%, rgba(184,184,184,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#525252', endColorstr='#b8b8b8', GradientType=1 );
    height: 75px;
    width: 100vw;
    position:fixed;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    @media(min-width:$tablet){
        justify-content: space-between;
    }
    .navbar__links {
        @media(max-width:$mobile) {
            a {
                font-size: 1.2rem;
            }
        }
    }
    .myName{
        display:none;
        @media(min-width:$tablet){
            display:block;
            padding-left: 35px;
        }
        h3{
            color:white;
            font-size: 1rem;
            @media(min-width:$desktop){
                font-size: 1.5rem;
            }
        }
    }
    a{
        color: white;
        text-decoration: none;
        font-size: 2rem;
        cursor: pointer;
    }
    .navbar__webLink{
        margin-right:50px;
    }
    #activeUnderline{
        border-bottom:thick white solid;
    }
    #inactiveUnderline{
        border:none;
    }
    .navbar__logos{
        display:none;
        padding-right:36px;
        @media(min-width:$tablet){
            display:block;
            padding-left: 35px;
        }
        img{
            padding-top:5px;
            padding-right:3px;
            height: 50px
        }
    }
}

.heroSection{
    height: 500px;
    color:white;
    position: relative;
    h1{
        position: absolute;
        top:20%;
        text-align: left;
        width:85%;
        font-size: 3rem;
        padding-left:10%;
        margin-top:5px;
        @media(min-width:$tablet){
            font-size: 4rem;
            width:78%;
            padding-left:17%;
            margin: 10px auto;
        }
        @media(min-width:$desktop){
            padding-left:21%;
        }
        @media(min-width:1440px){
            margin-top:54px;
            padding-left:21%;
        }
    }
}

.webHero{
    background-image: url('./webbg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.videoHero{
    background-image: url('./videobg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    h1{
        padding-left:9%;
        top:15%;
        @media(min-width:$tablet){
            padding-left:15%;
        }
        @media(min-width:$desktop){
            top:20%;
        }
    }
}

.bioSection{
    padding-bottom:20px;
    .bioSection__header{
        text-align: center;
        font-size: 2rem;
        margin-top:0;
        padding-top:20px;
    }
    .stackAndBackground{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
        .webTech{
            img{
                width:90%;
                padding-left: 25px;
                @media(min-width:$tablet){
                    margin-right:20px;
                }
            }
            @media(min-width:$tablet){
                width:50%
            }
        }
        .background_text{
            width:90%;
            font-size: 1.2rem;
            @media(min-width:$tablet){
                width:90%;
                padding-right:15px;
            }
            @media(min-width:$desktop){
                width:40%;
            }
        }
    }

}
.webBio{
    background-color: rgba(82,82,82,1);
    color:white;
    text-align: left;
}
.videoBio{
    background-color: rgba(184,184,184,1);
    color:black;
    text-align: left;
}


.projects{
    .projects__header{
        margin-top: 0;
        padding-top:20px;
        font-size: 2rem;
    }
    .projectCards{
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
}

.webBackground{
    background-color: rgba(82,82,82,1);
}
.webLightText{
    color: white;
}
.videoBackground{
    background-color: rgba(184,184,184,1);
}
.videoDarkText{
    color:black
}

.card{
    height: 400px;
    width: 400px;
    border-radius: 5px;
    box-shadow: 2px 2px 2px -1px rgba(0,0,0,0.75);
    background-color: white;
    margin:20px;
    img{
        width:100%;
        height: 50%;
        object-fit: cover;
    }
    .card__text{
        width:90%;
        text-align: left;
        margin:0 auto;
        position: relative;
        .projectLink{
            position: absolute;
            top:146px;
            text-decoration: none;
        }
    }
}

.quotes-section{
    background-color: rgba(82,82,82,1);
    padding-bottom: 20px;
    h1{
        color: white;
        font-size: 2rem;
        margin: 0;
        padding-top: 20px;
    }
    .quotes-box{
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        .quote-text{
            max-width: 500px;
            color: white;
            text-align: left;
            display: flex;
            justify-content: center;
            margin-top: 16px;
            .quote-body{
                font-size: 1.5rem;
                @media(max-width:$mobile){
                    font-size: 1rem;
                }
            }
            .opening-quote{
                height: 40px;
                padding-right: 15px;
                @media(max-width:$mobile){
                    height: 50px;
                }
            }
            .closing-quote{
                height: 40px;
                padding-top: 150px;
                padding-left: 15px;
                @media(max-width:$mobile){
                    height: 50px;
                    padding-top: 0;
                }
            }
        }
    }

}

.contactMe{
    padding-bottom:50px;
    .contactMe__header{
        margin-top: 0;
        padding-top:10px;
        font-size: 2rem;
    }
    .contact__Circles{
        .myPortrait{
            border-radius: 50%;
            height:166px;
            border: 17px black solid;
        }
        img{
            height: 200px;
            margin:0 10px;
        }
    }
}

.greyBg {
    background-color: rgba(184,184,184,1);
}

.artHero{
    background-image: url('./artstock.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    h1{
        padding-left:9%;
        top:37%;
        @media(min-width:$tablet){
            padding-left:15%;
        }
        @media(min-width:$desktop){
            top:38%;
        }
        @media(min-width:1025px){
            top:31%;
        }
    }
}

.comicsMap {
    padding: 24px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    .comic-thumbnail {
        width: 100px;
        height: 170px;
        padding: 10px;
        border: thin black solid;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
            width:100%;
        }
    }
}

.comicModalBody {
    .comicModalHeader {
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        .closeButton {
            height: 50px;
            width: 100px;
            background-color: red;
            border-radius: 5px;
            border: none;
            cursor: pointer;
            color: white;
            font-size: 1.2rem;
        }
    }
    img {
        width: 100%;
    }
}